@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "fonts";
@import "inputs";
@import "colors";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Nunito";
}

body {
  margin: 0;
  min-width: 360px;
}
$test: green;

//Dark
$dark-background-color: #211f1c;
$dark-text-color: #dfe6e9;
$dark-header: #111414;
$dark-hover: #2d3436;

//Light
$light-background-color: #e9eff2;
$light-text-color: #2d3436;
$light-header: #ffffff;
$light-hover: #f4f4f4;

body.dark-mode {
  background-color: $dark-background-color;
  color: $dark-text-color;
  .logo {
    filter: invert(1);
  }
  .header {
    background-color: $dark-header;
    a {
      color: $dark-text-color;
    }
    li a {
      // border-right: 1px solid $dark-hover;
    }
    li a:hover,
    li .toggle:hover,
    .menu-btn:hover {
      background-color: $dark-hover;
    }
    .menu-icon {
      .navicon,
      .navicon:before,
      .navicon:after {
        background-color: $dark-text-color;
      }
    }
    .mainlink:hover {
      border-bottom: 2px $dark-text-color solid;
    }
  }
  .submenu {
    background-color: $dark-header;
    color: $dark-text-color;
    ul > li:hover{
      
      border-bottom: 2px $dark-text-color solid;
    } 
  }
}

body.light-mode {
  background-color: $light-background-color;
  color: $light-text-color;

  .header {
    background-color: $light-header;
    a {
      color: #2d3436;
    }
    li a {
      border-right: 1px solid $light-hover;
    }
    li a:hover,
    li .toggle:hover,
    .menu-btn:hover {
      background-color: $light-hover;
    }
    .menu-icon {
      .navicon,
      .navicon:before,
      .navicon:after {
        background-color: $light-text-color;
      }
    }
    .mainlink:hover {
      border-bottom: 2px $light-text-color solid;
    }
  }
  .submenu {
    background-color: $light-header;
    color: $light-text-color;
    ul > li:hover{
      
      border-bottom: 2px $light-text-color solid;
    } 
  }
}
body:not(.dark-mode-preloading) {
  transition: all 1s linear;
}

h1 {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (max-width: 766px) {
  h1 {
    font-size: 1.87rem;
    padding-top: 1.87rem;
    padding-bottom: 1.87rem;
    margin-bottom: 0.5rem;
  }
}

.btn {
  margin: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 12px;
  position: relative;
  text-align: center;
  transition: all 0.15s linear;
  background-color: $blue2;
  border-color: $blue2;
  color: #fff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  padding: 12px 24px;
  border: 1px white solid;
}


.btn-light {
  color: $blue2;
  background-color: white;
  border-color: white;
}

.btn:disabled {
  background-color: $gray;
  border-color: $gray;
  cursor: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c8c8c8 #181818;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #c8c8c8;
}

*::-webkit-scrollbar-thumb {
  background-color: #181818;
  border-radius: 0px;
  border: 0px none #ffffff;
}
