.field {
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-bottom: 2px solid rgb(0, 0, 0);
    margin: 4rem auto 1rem;
    transition: 500ms;
}

.field-light {
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-bottom: 2px solid white;
    margin: 4rem auto 1rem;
    transition: 500ms;
}

.area-field {
    margin: 4rem 0 1rem;
}
.label {
    color: rgb(0, 0, 0);
    font-size: 1rem;
    font-weight: 600;
    padding-left: 0.5rem;
}

.input {
    outline: none;
    border: none;
    overflow: hidden;
    margin: 0;
    width: 100%;
    padding: 0.25rem 0;
    background: none;
    color: rgb(0, 0, 0);
    font-size: 1.2em;
    font-weight: bold;
    transition: border 500ms;
}
.textarea {
    resize: vertical;
    max-height: 300px;
    background: none;
    margin-top: 1rem;
}
.field::after {
    content: "";
    position: relative;
    display: block;
    height: 4px;
    width: 100%;
    background: rgb(0, 0, 0);
    transform: scaleX(0);
    transform-origin: 0%;
    opacity: 0;
    transition: all 500ms ease;
    top: 2px;
}
.field-light::after {
    content: "";
    position: relative;
    display: block;
    height: 4px;
    width: 100%;
    background: white;
    transform: scaleX(0);
    transform-origin: 0%;
    opacity: 0;
    transition: all 500ms ease;
    top: 2px;
}

.field:focus-within {
    border-color: transparent;
}
.field:focus-within::after {
    transform: scaleX(1);
    opacity: 1;
}


.field-light:focus-within {
    border-color: transparent;
}
.field-light:focus-within::after {
    transform: scaleX(1);
    opacity: 1;
}